<template>
  <div :style="itemPadding">
    <div class="origin-form-tree-item" @click="hasChildren ? toggleChildren() : toggleSelectOrigin()">
      <span
        class="origin-form-tree-item-dot"
        :class="{ 'origin-form-tree-item-dot-selected': isSelect || isSelectParent }"
        :has-parent="item.parent != null"
      />
      {{ item.name }}
    </div>
    <div v-if="hasChildren" v-show="showChildren">
      <origin-form-tree
        v-for="child in item.children"
        :key="child.id"
        :item="child"
        :spacing="spacing + 38"
        :is-current-open="isCurrentOpen"
        :is-current-select="isCurrentSelect"
        :is-current-select-parent="isCurrentSelectParent"
      />
    </div>
  </div>
</template>

<script>
import Check from '@/assets/icons/ico-check.svg'

export default {
  name: 'OriginFormTree',
  props: {
    isCurrentOpen: {
      type: String,
      default: '',
    },
    isCurrentSelect: {
      type: Object,
      default: null,
    },
    isCurrentSelectParent: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      required: true,
    },
    spacing: {
      type: Number,
      default: 0,
    },
  },
  emits: ['select-origin', 'close-parents'],
  data: function () {
    return {
      icon: {
        check: Check,
      },
      isSelect: false,
      isSelectParent: false,
      showChildren: false,
    }
  },
  computed: {
    hasChildren() {
      const { children } = this.item
      return children && children.length > 0
    },

    itemPadding() {
      return {
        'padding-left': `${this.spacing}px`,
      }
    },
  },

  watch: {
    isCurrentOpen(val) {
      if (val !== this.item.uid) {
        this.showChildren = false
      }
    },
    isCurrentSelect(val) {
      this.isSelect = val !== null && val.uid === this.item.uid
    },
    isCurrentSelectParent(val) {
      this.isSelectParent = val !== null && val === this.item.uid
    },
  },

  methods: {
    toggleSelectOrigin() {
      this.isSelect = !this.isSelect

      this.toggleChildren()

      if (this.item.parent === null) {
        this.$emit('select-origin', this.isSelect ? this.item : null)
      } else {
        this.$parent.$emit('select-origin', this.isSelect ? this.item : null)
      }
    },

    toggleChildren() {
      this.showChildren = !this.showChildren

      if (this.item.parent === null) {
        this.$emit('close-parents', this.item.uid)
      }
    },
  },
}
</script>

<style lang="scss">
.origin-form-tree-item {
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 15px;

  font-family: 'Avenir';
  font-size: pxToRem(12px);
  font-weight: 900;
  color: var(--steel-grey);

  cursor: default;
  cursor: pointer;

  &-dot {
    display: inline-block;
    height: 18px;
    width: 18px;
    margin-right: 15px;
    border-radius: 50%;
    background-color: var(--light-grey);
    cursor: pointer;

    &-selected {
      background:
        url('@/assets/icons/ico-check.svg') no-repeat center/10px 7px,
        var(--primary);

      &[has-parent='true'] {
        background-color: var(--steel-grey);
      }
    }
  }
}
</style>
