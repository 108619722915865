<template>
  <div class="origin-form" @mouseleave="closeMenu">
    <select-field
      id="value"
      ref="selectField"
      :selected-value="selectedOrigin ? selectedOrigin.name : ''"
      :placeholder-value="$t('subscribe.origin')"
      :icon-left="iconLeft"
      :required="required"
      :input-rules="inputRules"
      class="u-f-grow-1 origin-form-input u-m-t-less"
      :class-variant="classVariant"
      @focus="showMenu = true"
      @blur="showMenu = false"
    >
      <template #icon>
        <div v-if="icon" :class="`icon-${icon} icon-large u-bg-${iconColor}`" />
      </template>
    </select-field>
    <div v-if="v$.selectedOrigin?.$invalid" class="origin-form-error">
      <span v-for="rule in Object.keys(rules)" :key="rule">
        <div v-if="Object.keys(v$.selectedOrigin).includes(rule) && !v$.selectedOrigin[rule]">
          {{ $t(`rules.${rule}`, v$.selectedOrigin.$params[rule].min || v$.selectedOrigin.$params[rule].max) }}
        </div>
      </span>
    </div>
    <div v-show="showMenu" class="origin-form-menu" :class="classVariant">
      <origin-form-tree
        v-for="(origin, i) in origins"
        :key="i"
        :item="origin"
        :is-current-open="currentOpenParent"
        :is-current-select="selectedOrigin"
        :is-current-select-parent="selectedOriginParent"
        @select-origin="selectOrigin"
        @close-parents="closeParents"
      />
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'

import API from '@/mixins/sp-api-mixin'
import rules from '@/mixins/rules-mixin'

import SelectField from '@/components/SelectField/SelectField'
import OriginFormTree from '@/components/Form/OriginForm/OriginFormTree.vue'

export default {
  name: 'OriginForm',
  components: { OriginFormTree, SelectField },
  mixins: [API],
  props: {
    iconLeft: { type: String, default: '' },
    required: { type: Boolean, default: false },
    inputRules: { type: Array, default: () => [] },
    classVariant: { type: null, default: null },
    icon: { type: String, default: '' },
    iconColor: { type: String, default: '' },
  },
  emits: ['update:modelValue'],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      currentOpenParent: null,
      origins: [],
      selectedOriginParent: null,
      selectedOrigin: null,
      showMenu: false,
    }
  },
  validations() {
    const selectedOrigin = {}
    for (const rule of this.inputRules) {
      if (Array.isArray(rule)) {
        selectedOrigin[rule[0]] = this.rules[rule[0]](rule[1])
      } else {
        selectedOrigin[rule] = this.rules[rule]
      }
    }
    return { selectedOrigin }
  },
  computed: {
    rules() {
      return rules
    },
  },
  async mounted() {
    const { $api } = useNuxtApp()
    const origins = await this.spRequest({
      req: $api().prospect.getOrigins(),
    })
    this.origins = origins.map(origin => {
      origin.children.forEach(o => {
        o.parent = origin.uid
      })
      return origin
    })
  },
  methods: {
    closeMenu() {
      if (this.showMenu) {
        this.$refs.selectField.toggleFocus()
      }

      this.showMenu = false
    },

    closeParents(currentOpenParent) {
      this.currentOpenParent = currentOpenParent
    },

    selectOrigin(origin) {
      if (this.origins) {
        this.selectedOrigin = origin

        this.selectedOriginParent = origin !== null && origin.parent !== null ? origin.parent : null

        this.$emit('update:modelValue', origin !== null ? origin.uid : null)
      }
    },
  },
}
</script>

<style lang="scss">
.origin-form {
  position: relative;
  width: 100%;

  &-error {
    font-weight: 500;
    color: var(--red);
    text-align: left;
  }

  &-menu {
    position: absolute;
    top: 38px;
    width: 100%;
    border-radius: 3px;
    background-color: var(--background-blue); // same color as input-field classic
    z-index: 1000;

    &.bg-grey {
      background-color: var(--bg-grey) !important;
    }
  }
}
</style>
